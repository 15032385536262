


// eslint-disable-next-line
import React from 'react';
import { Ratio } from 'react-bootstrap/'
import CompetenceInterface from "../../interface/competence.1";
import { MDBContainer, MDBIframe } from "mdbreact";

export default class CardComponent extends React.Component {
   

render() {
    return (
    
            <div>
            <MDBContainer className="">
                <Ratio className=" ratio-16x9">
                    <MDBIframe src="https://www.youtube.com/embed/v64KOxKVLVg" />
                </Ratio>
                </MDBContainer>
            </div> 


  
 
        
        );
    }
};
 