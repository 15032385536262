
import imgManga from '../../../src/img/backgroundimage.png';

export const DataProjet= [
    {
       
        "projet": "Manga",
        "img": imgManga,
        "alt":"image du projet manga",
        "description": "site de manga: une présentation ... ",
        "projetLink":"https..."
        
    },

]
    






 

