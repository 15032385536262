// eslint-disable-next-line
import React from 'react';
import { Card, ListGroup, ListGroupItem } from 'react-bootstrap'
import CompetenceInterface from "../../interface/competence.1";


export default class CardComponent extends React.Component {
    constructor(props: CompetenceInterface) {
       super(props);
        
    }

render() {
    return(

        <Card style={{ width: '18rem' }} className='white bg-dark'>
            <Card.Img width="193" height="130" variant="top" src={this.props.img} />
            <Card.Body>
                <Card.Title style={{  color: 'white' }}>{this.props.title}</Card.Title>
                <Card.Text style={{ height: '13rem' , color: 'white' }}>
                    {this.props.description}
                    
                </Card.Text>
            </Card.Body>
            <ListGroup className="list-group " >
                <ListGroupItem style={{  color: 'white' }} className='bg-dark'>{this.props.nom}</ListGroupItem>
               
            </ListGroup>
            <Card.Body >
                <Card.Link href={this.props.doc} style={{  color: 'white' }}>Documentation Officiel</Card.Link>
            </Card.Body>
        </Card>
        );
    }
};
 




