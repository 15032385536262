import React from 'react'
import CardCompetence from '../Component/competence/card';
import { Container, Row } from 'react-bootstrap';
import { stockdata } from '../interface/data/dataCompetence';

function Competence() {


    return (
    <><Container style={{ backgroundColor: 'blueviolet' }}>  
        <Row xs={1} md={12} xxl={12} xl={12}  lg={12} sm={12}  className='text-center' >
                <h1>Mes Compétence et Ma Veille Technologique</h1>
        </Row>
            <br />
        <Row xs={1} md={3} xxl={4} xl={3}  lg={2} sm={2} className="g-4"> 
        <br />  
        {stockdata.map((data) => {
          return (
            <div >
              <CardCompetence  title={data.title} img={data.img} nom={data.nom} description={data.description} doc={data.doc} />
              <br/>
            </div>
          );
        })}
        </Row>
      </Container>
    </>


    );


}
export default Competence;