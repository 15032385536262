// eslint-disable-next-line
import * as React from 'react';
import { Tbody } from 'react-bootstrap'

import VarEnvInterface from "./../../interface/varEnv";


export default class TableComponent extends React.Component {
    constructor(props: VarEnvInterface) {
       super(props);
        
    }
    render(){
    return(

       
            <tbody>
           
             <tr>
               
                <td>{ this.props.id }</td>
                <td>{ this.props.nomdelavariable }</td>
                <td>{ this.props.variable }</td>
                <td>{ this.props.description}</td>
            </tr>
            
            </tbody>
        
        );
    }
};
        
    