import React from 'react'
import CardProjets from '../Component/Projets/card';
import {Container,Row} from 'react-bootstrap';
import {DataProjet} from '../interface/data/dataProjet';



function Projet()  {
    return (
 <><Container style={{ backgroundColor: 'blueviolet', minHeight: 1000 }} className='m-100'>  
        <Row xs={1} md={12} className='text-center' >
                <h1>Mes Projets</h1>
        </Row>
      <br />
      <br /> 
      <Row xs={1} md={12} className="g-4"> 
         
        {DataProjet.map((data) => {
          return (
            <div >
              <CardProjets projet={data.projet} img={data.img} description={data.description} projetLink={data.projetLink}/>
              <br/>
            </div>
          );
        })}
        </Row>
    </Container>
      
    </>
 );
};

export default Projet;