
import * as React from 'react';
import { Card, Button, Ratio } from 'react-bootstrap';
import ProjetInterface from "../../interface/projetInterface";



 class CardProjet extends React.Component<ProjetInterface, {}> {
    constructor(props: ProjetInterface) {
       super(props);
        
    }
    
 
render() {
    return (
     
        <Card className="bg-dark text-white hv-100">
            <Ratio aspectRatio={15}>
                <Card.Img src={this.props.img}   alt={this.props.alt} />
                
            </Ratio>
            
            <Card.ImgOverlay className=''>
                <Card.Title>{this.props.projet}</Card.Title>
                <Card.Text>
                {this.props.description}
                </Card.Text>
                <Button variant="outline-dark" size="lg"  >
                     <Card.Link href={this.props.projetLink} style={{  color: 'white' }}>Voir mon projet</Card.Link>
                </Button>
               
            </Card.ImgOverlay>
        </Card>
        
  
        
        );
    }
};
export default CardProjet;