import * as React from 'react';



function NoPages() {
    return (
        <div>page NoPages </div>
        );
};

export default NoPages;