import React from 'react'
import Video from '../Component/presentation/presentation';
import {Container,Row, Col} from 'react-bootstrap';



function test() {
     

  return (

    <Container>
      <Row>
        <div>
      <small>You are running this application in <b>{process.env.NODE_ENV}</b> mode.</small>
      <form>
        <input type="hidden" defaultValue={process.env.REACT_APP_NOT_SECRET_CODE} />
      </form>
    </div>
      </Row>
      <Row>
        <Col>
          <br/>
          <br/>
          <br/>
        </Col>
      </Row>
      <Row>
        
    <Video/>
      </Row>
   </Container>
       
    
    );


}
export default test;