
import imghtml from '../../../src/img/logohtml.jpg';
import imgCss from '../../../src/img/CSS.png';
import imgjs from '../../../src/img/JS.png';
import imgSass from '../../../src/img/SASS.jpg';
import imgBootstrap from '../../../src/img/BOOTSTRAP.jpg';
import imgHandlebars from '../../../src/img/handlebar.jpg';
import imgReactjs from '../../../src/img/logo512.png';
import imgAngularjs from '../../../src/img/ANGULAR.png';
import imgUbuntu from '../../../src/img/UBUNTU.png';
import imgNginx from '../../../src/img/Nginx.svg';
import imgApache from '../../../src/img/APACHE.png';
import imgOwasp from '../../../src/img/owaps.jpg';
import imgWordpress from '../../../src/img/wordpress-logo.png';
import imgPrestashop from '../../../src/img/prestashop_logo.png';
import imgNode from '../../../src/img/node.webp';
import imgPhp from '../../../src/img/php.png';
import imgMongoDB from '../../../src/img/mongodb.png';
import imgMySQL from '../../../src/img/1200px-MySQL.svg.png';
import imgTSQL from '../../../src/img/T-sql.jpeg';





export const stockdata = [
    {
       
        "title": " Html ",
        "img": imghtml,
        "nom": "Langage de programmation",
        "description": "Voici le language de balissage qui permet de creer une interface graphique pour les site web",
        "doc":"https://jaetheme.com/balises-html5/"
        
    },
    {
        
        "title": "Css ",
        "img": imgCss,
        "nom": "Langage de programmation",
        "description": "Les feuilles de style en cascade, appelées CSS  forment un langage informatique qui décrit la présentation des documents HTML et XML. Le CSS devient couramment utilisé dans la conception de sites web et bien pris en charge par les navigateurs web .",
        "doc": ""
    },
    {
        
        "title": "JavaScript",
        "img": imgjs,
        "nom": "langage de programmation",
        "description": "JavaScript est un langage de programmation de scripts principalement employé dans les pages web interactives et à ce titre est une partie essentielle des applications web.",
        "doc": "https://www.javascript.com/"
    },
    {
        
        "title": " Sass",
        "img": imgSass,
        "nom": "Syntactically awesome stylesheets",
        "description": "Sass est un langage de script préprocesseur qui est compilé ou interprété en CSS (Cascading Style Sheets)."
        , "doc": "https://sass-lang.com/"
    },
    {
        
        "title": "Bootstrap",
        "img": imgBootstrap,
        "nom": "Framework",
        "description": "Bootstrap est une collection d'outils utiles à la création du design (graphisme, animation et interactions avec la page dans le navigateur, etc.) de sites et d'applications web.",
        "doc": "https://getbootstrap.com/"
    },
    {
        
        "title": " Handlebars",
        "img": imgHandlebars,
        "nom": "moteur de template",
        "description": "Handlebars ou HBS est un moteur de template, le projet a été créé par Yehuda Katz en 20101. Handlebars étend les fonctionnalités de Mustache (système de template sans-logique/logic-less2), tout en étant compatible avec les gabarits de ce dernier.",
        "doc": "https://handlebarsjs.com/"
    },
    {
        
        "title": "Reactjs",
        "img": imgReactjs,
        "nom": "Bibliothèque JavaScript",
        "description": "React  est une bibliothèque JavaScript libre ,le but principal de cette bibliothèque est de faciliter la création d'application web monopage.",
        "doc": "https://fr.reactjs.org/"
    },
    {
        
        "title": "Angularjs",
        "img": imgAngularjs,
        "nom": "Framework JavaScript",
        "description": "AngularJS1 est un framework JavaScript libre et open source développé par Google. Il permet de développer des pages web.",
        "doc": "https://angularjs.org/"
    },
    {
        
        "title": " Ubuntu",
        "img": imgUbuntu,
        "nom": "Système d'exploitation",
        "description": "Ubuntu est un système d’exploitation GNU/Linux basé sur Debian. Il est développé, commercialisé et maintenu pour les ordinateurs individuels (desktop), les serveurs et les objets connectés  par la société Canonical.",
        "doc": "https://doc.ubuntu-fr.org/"
    },
    {
        
        "title": "Nginx",
        "img": imgNginx ,
        "nom": "Serveur web",
        "description": "NGINX Open Source4 ou NGINX  est un logiciel libre de serveur Web (ou HTTP) ainsi qu'un proxy inverse   C'est depuis avril 2019, le serveur web le plus utilisé au monde d'après Netcraft5, ou le deuxième serveur le plus utilisé d'après W3techs",
        "doc": "https://www.nginx.com/"
    },
    {
        
        "title": "Apache",
        "img": imgApache ,
        "nom": "Serveur web",
        "description": "Le logiciel libre Apache HTTP Server (Apache) est un serveur HTTP créé et maintenu au sein de la fondation Apache. Jusqu'en avril 20192, ce fut le serveur HTTP le plus populaire du World Wide Web. Il est distribué selon les termes de la licence Apache.",
        "doc": "https://httpd.apache.org/docs/"
    },
    {
        
        "title": "Owasp",
        "img": imgOwasp,
        "nom": "Organisation à but non lucratif",
        "description": "Open Web Application Security Project (OWASP) est une communauté en ligne travaillant sur la sécurité des applications Web. Sa philosophie est d'être à la fois libre et ouverte à tous.",
        "doc": "https://owasp.org/"
    },
    {
        
        "title": "Wordpress",
        "img": imgWordpress,
        "nom": "Système de gestion de contenu",
        "description": "WordPress est un système de gestion de contenu gratuit, libre et open-source. Ce logiciel écrit en PHP repose sur une base de données MySQL et est distribué par la fondation WordPress.org. ",
        "doc": "https://fr.wordpress.org/support/"
    },
    {
        
        "title": "Prestashop",
        "img": imgPrestashop ,
        "nom": "Système de gestion de contenu",
        "description": "PrestaShop est une application Web open source permettant de créer une boutique en ligne dans le but de réaliser du commerce électronique. L'application est publiée selon les termes de la licence Open Software 3.07.",
        "doc": "https://www.prestashop.com/fr/ressources"
    },
    {
        
        "title": "Node",
        "img": imgNode,
        "nom": "Environnement d’exécution",
        "description": "Node.js est une plateforme logicielle libre en JavaScript, orientée vers les applications réseau évènementielles hautement concurrentes qui doivent pouvoir monter en charge.",
        "doc": "https://nodejs.org/en/docs/"
    },

    {
        
        "title": " Php",
        "img": imgPhp,
        "nom": "Langage de programmation",
        "description": " PHP,principalement utilisé pour produire des pages Web dynamiques , mais pouvant également fonctionner comme n'importe quel langage interprété de façon locale. PHP est un langage impératif orienté objet.",
        "doc": "https://www.php.net/docs.php"
    },
    {
        
        "title": "MongoDB",
        "img": imgMongoDB,
        "nom": "Système de gestion de base de données",
        "description": "MongoDB est un système de gestion de base de données orienté documents, répartissable sur un nombre quelconque d'ordinateurs et ne nécessitant pas de schéma prédéfini des données.",
        "doc": "https://www.mongodb.com/fr-fr"
    },
    {
        
        "title": "MySQL",
        "img": imgMySQL,
        "nom": "Système de gestion de base de données relationnelle",
        "description": "MySQL  est un système de gestion de bases de données relationnelles (SGBDR). Il est distribué sous une double licence GPL et propriétaire.",
        "doc": "https://dev.mysql.com/doc/"
    },
    {
        
        "title": "T-SQL",
        "img": imgTSQL,
        "nom": "Langage de programmation",
        "description": `Le Transact-SQL (T-SQL) est une extension propriétaire de Sybase et Microsoft au langage SQL1.  `,
        "doc": "https://docs.microsoft.com/fr-fr/sql/t-sql/language-reference?view=sql-server-ver15"
    }
  

]
    






 

