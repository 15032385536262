import React from 'react'
import { Footer, Nav} from 'react-bootstrap';
import { MDBFooter } from 'mdb-react-ui-kit';


const footer = () => {
    return (

          
        
           <MDBFooter style={{ backgroundColor: '#8712f5'}} className='text-center text-lg-start text-muted'>
                <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
                    <div className='me-5 d-none d-lg-block'>
                        <span>Rejoignez-nous sur les réseaux sociaux :</span>
                    </div>

                    <div>
                       
                       
                        
                       
                        <a href='https://www.linkedin.com/in/j%C3%A9r%C3%B4me-esnault-dev/' className='me-4 text-reset'>
                            <i className='fab fa-linkedin'></i>
                        </a>
                        <a href='https://github.com/jeromeEsnault?tab=repositories' className='me-4 text-reset'>
                            <i className='fab fa-github'></i>
                        </a>
                    </div>
                </section>

                <section className=''>
                    <div className='container text-center text-md-start mt-5'>
                        <div className='row mt-3'>
                           

 
                  
                         <div className='col-md-3 col-lg-2 col-xl-2 mx-auto mb-4 ' text={'light'}>
                            <h6 className='text-uppercase fw-bold mb-4'>Liens utiles</h6>
                           
                                <Nav defaultActiveKey="/"  className="flex-column ">
                                    <Nav.Link href="/" className='text-muted' >   Présentation</Nav.Link>
                                    <Nav.Link eventKey="/Competence"  className='text-muted'>Compétences</Nav.Link>
                                    <Nav.Link eventKey="link-2" className='text-muted'>Projets</Nav.Link>
                                </Nav>
                            </div>

                            <div className='col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4'>
                                <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
                                <p>
                                    <i className='fas fa-home me-3'></i> 3 rue du Docteur Leroy
                                </p>
                                <p>
                                    <i className='fas fa-envelope me-3'></i>
                                    esnaultjerome72@gmail.com
                                </p>
                                <p>
                                    <i className='fas fa-phone me-3'></i> 06 47 80 05 91
                                </p>
                               
                            </div>
                        </div>
                    </div>
                </section>

                <div className='text-center p-4' style={{ backgroundColor: '#0c0b0bc' }}>
                   Création de Jérôme ESNAULT
                </div>
            </MDBFooter>
          
        );
    }

           
 


export default footer;