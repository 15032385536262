import  React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import '../src/bootstrap/bootstrap-5.1.3-dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import App from './Component/App'
import Param from './paramEnv/navParam'
//import SSRProvider from 'react-bootstrap/SSRProvider';

//import { Provider } from "react-redux";
//import { compose, createStore } from "react-redux";
//import { rootReducer } from "./store/reducers";
//import { initialState } from "./store/state";
//const middlewares = [];
/*export const store = createStore(
    //rootReducer,
    //initialState,
    //compose(applyMiddleware(...middlewares)),
);*/



ReactDOM.render(
  
     <Suspense fallback={<div>Chargement...</div>}>
     
          <App />
      </Suspense>
  ,
    document.getElementById("root"),
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
