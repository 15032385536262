import React from 'react';
import Navbar from '../Component/core/navbar/Navbar';
import Footer from '../Component/core/footer/footer';

function Layout(){
    return (
        <>
        <Navbar />

        <Footer />
        
        </>
        
 );
};

export default Layout;