
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Test from "../pages/test";
import Layout from '../Layout/layout';
import Home from '../pages/presentation';
import Blogs from '../pages/Projet';
import Competence from '../pages/Competence';
import NoPage from '../pages/NoPage';
import EnvPages from '../pages/VarEnv'; 
import React from "react";
function App() {
    return (
        <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout/>}>
          <Route index element={<Home/>} />
          <Route path="/Projet" element={<Blogs />} />
          <Route path="/competence" element={<Competence />} />
          <Route path="/test" element={<Test />} />
          <Route path="/varEnv" element={<EnvPages/>} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
    );
}

export default App;