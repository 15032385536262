import React from 'react'
import Video from '../Component/presentation/presentation';
import {Container,Row, Col} from 'react-bootstrap';


function presentation() {
    return (
<Container>
      <Row>
        <Col>
          <br/>
          <br/>
          <br/>
        </Col>
      </Row>
      <Row>
          <Video />
          <Col>
           <br/>
          </Col>
         
      </Row>
   </Container>
       
 );
};

export default presentation;