import React from 'react'
import {Navbar, Nav, Container, Image} from 'react-bootstrap';
import { Outlet } from "react-router-dom";
import LogoMaison from "./../../../img/LogoPerso/logoPerso.svg"

const navbar = () => {
    return (
    
        <><Navbar bg="light" variant="light" expand="lg" >
            <Container fluid>
                <Navbar.Brand href="#">  <img
        src= {LogoMaison}
        width="60"
        height="60"
        className="d-inline-block align-top"
        alt="React Bootstrap logo"
      /> </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>
                       <Nav.Link href="/"> 
                       Présentation
                        </Nav.Link>
                       <Nav.Link href="/Competence" > 
                      Compétences
                        </Nav.Link>
                      <Nav.Link href="/Projet"> 
                         Projets
                        </Nav.Link> 
                      
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
         <Outlet/>   </> 
 );
};

export default navbar;